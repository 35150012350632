import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { H1, Paragraph } from 'src/DesignSystem';
import { faCrown, faVenus, faMars, faVenusMars, faRandom } from '@fortawesome/pro-solid-svg-icons';
import NameGenerator, {
  GeneratorInfo,
  multiListGenerator,
  singleListGenerator,
} from 'src/components/NameGenerator';
import NameTables from 'src/resources/name-tables';

const PAGE_DESCRPTION = `A number of useful name generators from the tables in Appendix B of "Xanathar's Guide to Everything."`;

function humanOptionsGenerator(femaleList: string[], maleList: string[]): GeneratorInfo[] {
  return [
    {
      name: 'Female',
      generator: singleListGenerator(femaleList, faVenus),
    },
    {
      name: 'Male',
      generator: singleListGenerator(maleList, faMars),
    },
    {
      name: faRandom,
      generator: multiListGenerator(
        {
          list: femaleList,
          icon: faVenus,
        },
        {
          list: maleList,
          icon: faMars,
        }
      ),
    },
  ];
}

export default function Names(): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Name Generator" description={PAGE_DESCRPTION} />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <H1>Name Generators</H1>
        <Paragraph>
          A number of useful name generators from the tables in Appendix B of &quot;Xanathar&apos;s
          Guide to Everything.&quot;
        </Paragraph>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          <NameGenerator
            name="Dragonborn"
            options={[
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.DragonbornFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.DragonbornMale, faMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.DragonbornFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.DragonbornMale,
                    icon: faMars,
                  }
                ),
              },
              {
                name: 'Clan',
                generator: singleListGenerator(NameTables.DragonbornClan, faCrown),
              },
            ]}
          />
          <NameGenerator
            name="Dwarf"
            options={[
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.DwarfFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.DwarfMale, faMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.DwarfFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.DwarfMale,
                    icon: faMars,
                  }
                ),
              },
              {
                name: 'Clan',
                generator: singleListGenerator(NameTables.DwarfClan, faCrown),
              },
            ]}
          />
          <NameGenerator
            name="Elf"
            options={[
              {
                name: 'Child',
                generator: singleListGenerator(NameTables.ElfChild, faVenusMars),
              },
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.ElfFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.ElfMale, faMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.ElfFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.ElfMale,
                    icon: faMars,
                  }
                ),
              },
              {
                name: 'Family',
                generator: singleListGenerator(NameTables.ElfFamily, faCrown),
              },
            ]}
          />
          <NameGenerator
            name="Gnome"
            options={[
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.GnomeMale, faMars),
              },
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.GnomeFemale, faVenus),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.GnomeFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.GnomeMale,
                    icon: faMars,
                  }
                ),
              },
              {
                name: 'Clan',
                generator: singleListGenerator(NameTables.GnomeClan, faCrown),
              },
            ]}
          />
          <NameGenerator
            name="Halfling"
            options={[
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.HalflingFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.HalflingMale, faMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.HalflingFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.HalflingMale,
                    icon: faMars,
                  }
                ),
              },
              {
                name: 'Family',
                generator: singleListGenerator(NameTables.HalflingFamily, faCrown),
              },
            ]}
          />
          <NameGenerator
            name="Half-Orc"
            options={[
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.HalfOrcFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.HalfOrcMale, faMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.HalfOrcFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.HalfOrcMale,
                    icon: faMars,
                  }
                ),
              },
            ]}
          />
          <NameGenerator
            name="Tiefling"
            options={[
              {
                name: 'Female',
                generator: singleListGenerator(NameTables.TieflingFemale, faVenus),
              },
              {
                name: 'Male',
                generator: singleListGenerator(NameTables.TieflingMale, faMars),
              },
              {
                name: 'Virtue',
                generator: singleListGenerator(NameTables.TieflingVirtue, faVenusMars),
              },
              {
                name: faRandom,
                generator: multiListGenerator(
                  {
                    list: NameTables.TieflingFemale,
                    icon: faVenus,
                  },
                  {
                    list: NameTables.TieflingMale,
                    icon: faMars,
                  },
                  {
                    list: NameTables.TieflingVirtue,
                    icon: faVenusMars,
                  }
                ),
              },
            ]}
          />
          <NameGenerator
            name="Human - Arabic"
            options={humanOptionsGenerator(
              NameTables.HumanArabicFemale,
              NameTables.HumanArabicMale
            )}
          />
          <NameGenerator
            name="Human - Celtic"
            options={humanOptionsGenerator(
              NameTables.HumanCelticFemale,
              NameTables.HumanCelticMale
            )}
          />
          <NameGenerator
            name="Human - Chinese"
            options={humanOptionsGenerator(
              NameTables.HumanChineseFemale,
              NameTables.HumanChineseMale
            )}
          />
          <NameGenerator
            name="Human - Egyptian"
            options={humanOptionsGenerator(
              NameTables.HumanEgyptionFemale,
              NameTables.HumanEgyptianMale
            )}
          />
          <NameGenerator
            name="Human - English"
            options={humanOptionsGenerator(
              NameTables.HumanEnglishFemale,
              NameTables.HumanEnglishMale
            )}
          />
          <NameGenerator
            name="Human - French"
            options={humanOptionsGenerator(
              NameTables.HumanFrenchFemale,
              NameTables.HumanFrenchMale
            )}
          />
          <NameGenerator
            name="Human - German"
            options={humanOptionsGenerator(
              NameTables.HumanGermanFemale,
              NameTables.HumanGermanMale
            )}
          />
          <NameGenerator
            name="Human - Greek"
            options={humanOptionsGenerator(NameTables.HumanGreekFemale, NameTables.HumanGreekMale)}
          />
          <NameGenerator
            name="Human - Indian"
            options={humanOptionsGenerator(
              NameTables.HumanIndianFemale,
              NameTables.HumanIndianMale
            )}
          />
          <NameGenerator
            name="Human - Japanese"
            options={humanOptionsGenerator(
              NameTables.HumanJapaneseFemale,
              NameTables.HumanJapaneseMale
            )}
          />
          <NameGenerator
            name="Human - Mesoamerican"
            options={humanOptionsGenerator(
              NameTables.HumanMesoamericanFemale,
              NameTables.HumanMesoamericanMale
            )}
          />
          <NameGenerator
            name="Human - Niger-Congo"
            options={humanOptionsGenerator(
              NameTables.HumanNigerCongoFemale,
              NameTables.HumanNigerCongoMale
            )}
          />
          <NameGenerator
            name="Human - Norse"
            options={humanOptionsGenerator(NameTables.HumanNorseFemale, NameTables.HumanNorseMale)}
          />
          <NameGenerator
            name="Human - Polynesian"
            options={humanOptionsGenerator(
              NameTables.HumanPolynesianFemale,
              NameTables.HumanPolynesianMale
            )}
          />
          <NameGenerator
            name="Human - Roman"
            options={humanOptionsGenerator(NameTables.HumanRomanFemale, NameTables.HumanRomanMale)}
          />
          <NameGenerator
            name="Human - Slavic"
            options={humanOptionsGenerator(
              NameTables.HumanSlavicFemale,
              NameTables.HumanSlavicMale
            )}
          />
          <NameGenerator
            name="Human - Spanish"
            options={humanOptionsGenerator(
              NameTables.HumanSpanishFemale,
              NameTables.HumanSpanishMale
            )}
          />
          <NameGenerator
            name="Human - All"
            options={humanOptionsGenerator(NameTables.HumanAllFemale, NameTables.HumanAllMale)}
          />
        </div>
      </div>
    </BasicLayout>
  );
}
