import React, { useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { H4 } from 'src/DesignSystem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonGroup from 'src/components/ButtonGroup';
import { rndArray } from 'src/helpers/helpers';

export interface GeneratorInfo {
  name: string | IconDefinition;
  generator: () => [string, IconDefinition];
}

interface Props {
  name: string;
  options: GeneratorInfo[];
}

export function singleListGenerator(
  list: string[],
  icon: IconDefinition
): () => [string, IconDefinition] {
  return () => [rndArray(list), icon];
}

export function multiListGenerator(
  ...lists: {
    list: string[];
    icon: IconDefinition;
  }[]
): () => [string, IconDefinition] {
  return () => {
    const arr = rndArray(lists);
    return [rndArray(arr.list), arr.icon];
  };
}

export default function NameGenerator(props: Props): JSX.Element {
  const [icon, setIcon] = useState<IconDefinition>();
  const [name, setName] = useState<string>();

  return (
    <div>
      <H4 className="text-center mb-1">{props.name}</H4>
      <ButtonGroup
        buttons={props.options.map(option => ({
          onClick: () => {
            const [name, icon] = option.generator();
            setName(name);
            setIcon(icon);
          },
          name: option.name,
        }))}
      />
      <div className="bg-gray-300 h-8 rounded mt-3 flex items-center px-2 py-1 text-gray-800">
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className="flex-grow font-medium text-center">{name}</span>
      </div>
    </div>
  );
}
